import 'normalize-css';
import 'index.scss';
import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

// Import all javascript files from src/_components
const componentsContext = require.context('bridgetownComponents', true, /.js$/);
componentsContext.keys().forEach(componentsContext);

const application = Application.start();
const context = require.context('./controllers', true, /.js$/);
application.load(definitionsFromContext(context));
