import { Controller } from "stimulus"
export default class extends Controller {

  connect() {
    this.sessionStorage = window.sessionStorage;
    this._displayPopupIfNeeded();
  }

  close(e) {
    e.preventDefault();
    this.element.classList.add("hide-popup")
    this.sessionStorage.setItem('popupClosed', true);
  }

  _displayPopupIfNeeded() {
    if (!this.sessionStorage.getItem('popupClosed')) {
      this.element.classList.remove('hide-popup');
    }
  }
}
