import { Controller } from "stimulus"
// import Swiper JS
import Swiper, { Pagination } from 'swiper';

export default class extends Controller {

  static targets = [ "pagination" ]

  connect() {
    this._initCarousel();
  }

  _initCarousel() {
    new Swiper('.swiper', {
      speed: 400,
      spaceBetween: 100,
      modules: [Pagination],
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });
  }

}
