import { Controller } from "stimulus"
export default class extends Controller {

   static targets = [ "sideMenu" ]

  connect() {
    this._initActivePage();
  }

  openChildrenContainer(e) {
    e.preventDefault();
    const sibling = e.currentTarget.nextElementSibling
    if (sibling.classList.contains("chevron")) {
      return sibling.nextElementSibling.classList.toggle('d-none');
    }
    sibling.classList.toggle('d-none');
  }

  toggleMobileNav(e) {
    e.preventDefault();
    this.sideMenuTarget.classList.toggle("visible")
  }

  rotateChevron(e) {
    e.preventDefault();
    e.currentTarget.nextElementSibling.classList.toggle("rotated")
  }

  rotate(e) {
    e.preventDefault();
    console.log(e.currentTarget)
    e.currentTarget.classList.toggle("rotated")
  }

  _initActivePage() {
    const currentUrl = window.location.href;
    const splittedUrl = currentUrl.split('/');
    const currentPagePath = splittedUrl[splittedUrl.length - 1];
    if (currentPagePath && currentPagePath != "#") {
      const cleanedPagePath = currentPagePath.split('?')[0].split('#')[0];
      document
        .querySelectorAll(`.main-entry.${cleanedPagePath}`)
        .forEach((entry) => {
          entry.classList.add('active');
        });
    }
  }
}
