import { Controller } from "stimulus"
import Typed from "typed.js"
export default class extends Controller {

   static targets = [ "destination" ]

  connect() {
    this._initAnimation();
  }

  _initAnimation() {
    const options = {
      strings: this._arrayOfStrings(),
      typeSpeed: 40,
      backDelay: 1200,
      loop: true,
      loopCount: Infinity,
      showCursor: false,
    };
    new Typed(this.destinationTarget, options)
  }

  _arrayOfStrings() {
    return this.destinationTarget.dataset.strings.split('///')
  }
}
